@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
  font-family: 'montserrat';
}
:root {
  --white: #f4f4f4;
  --black: #050A30;
  --main: #4CB5F5;
  --blackish: #000;
  --true: #fff;
  --offwhite: #f0f0f0;
  --orange:#F58C4C;
  --gold:  #F6BE00;
}
.title{
  margin-bottom: 20px;
}
section {
  min-height: 100vh;
  padding: 50px;
}
li {
  list-style: none;
}
a{
  text-decoration: none;
}
h1{
  font-size: 1.5rem;
}
h4{
  font-size: 1.1rem;
  font-weight: 600;
}
p{
  font-size: .9rem;
}
nav{
  display: flex;
  justify-content: space-between;
  padding: 20px; 
  height: 10vh;
  align-items: center;
  z-index: 99;
  position: relative;
  background: linear-gradient(to right, rgba(5, 0, 48, .9), rgba(5, 0, 48, .9));
  /* background: var(--blackish); */
}
.nav-logo{
  width: 60%;
  max-width: 200px;
}
.navlinks{
  position: relative;
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  gap: 50px;
  padding:0;
}
.nav-link{
  color: var(--white);
  font-weight: bold;
}
.nav-link:hover{
  color: var(--main);
}
.bars{
  display: none;
}
.navlink:hover{
  background: var(--main);
}
.footer{
  background: var(--main);
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.footer p{
  text-align: center;
  /* background: var(--white); */
  color: var(--white);
  font-weight: lighter;
  font-size: .7rem;
  /* padding: 10px; */
}
.footer-wrapper{
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  gap: 20px;
  margin: 0 auto;
}

.left {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  /* Align content to the left */
}

/* Footer logo style */
.footer-logo {
  width: 50%;
  max-width: 400px;
}

/* Center content on small screens */
@media (max-width: 768px) {
  .left {
    align-items: center;
    /* Center content horizontally on small screens */
  }
}
.info .contact-icon{
  color: var(--white);
}

.footer-icon{
  color: var(--white);
  font-size: 1rem;
}
.footer-info{
  display: flex;
  flex-direction: column;
  gap:5px;
  font-size: 1px;
}
.info{
  margin-top: 5px;
  display: flex;
  /* gap: 0px; */
  /* align-items: center; */
  color: var(--white);
}
.subscribe{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.sub-title{
  font-weight: bold;
  color: var(--white);
}
.subscribe input{
  padding: 10px;
  width: 300px;
  text-align: center;
}
.sub-btn{
  background: var(--main);
  color: var(--white);
  font-weight: bold;
  cursor: pointer;
  border: none;
}
.sub-btn:hover{
  box-shadow: 5px 5px 10px #ccc;
}
.social-media{
  padding: 10px 0;
  display: flex;
  gap:5px;
}
.social-icon{
  color: var(--white);
  font-size: 0.5rem;
  cursor: pointer;
}
#one{
  flex-direction: column;
}
@media only screen and (min-width: 600px) {
  *{
    font-size: 16px;
  }
  .social-icon{
    font-size: 2rem;
  }

}
@media only screen and (max-width: 600px){
  .bars{
    color: var(--main) !important;
    font-size: 2rem;
    cursor: pointer;
    z-index: 999;
    display: block;
  }
  .bars:hover{
    color: var(--offwhite);
  }
  .navlinks{
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--black);
    flex-direction: column;
    width: 100vw;
    left: 0;
    top: 10vh;
    z-index: 999;
    color: var(--white);
    gap: 20px;
    padding:20px;
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 900px) {
  *{
    font-size: 18px;
  }
  nav{
    justify-content: space-around;
  }
  .nav-inactive,
  .nav-active{
    display: none;
  }
}

@media (max-width: 768px) {
  .footer-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding-bottom: 50px;
  }

  .social-icon {
    font-size: 2rem;
  }
}