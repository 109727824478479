.hero{
    background: linear-gradient(to right, rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)),  url(../images/field.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.hero-img{
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
}

.services {
    background: linear-gradient(to right, rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)), url(../images/hero.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 100px 20px;
}
.main-title{
    font-size: 1.6rem;
    font-weight: bold;
    color: var(--white);
    text-transform: uppercase;
    text-align: center;
}
.gallery {
    display: grid;
    grid-template-columns: 1fr;
    /* Default to 1 column */
    gap: 10px;
    /* Spacing between images */
}

.gallery img {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    object-fit: cover;
}
/* Media query for larger screens */
@media (min-width: 768px) {
    .gallery {
        grid-template-columns: repeat(3, 1fr);
        /* 3 columns on larger screens */
    }
}
.button{
    display: flex;
    flex-direction: column;
    margin: 50px 0;
    gap: 20px;
}
.one, .two{
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: bold;
}
.one{
    color: var(--white);
    background: var(--main);
}
.two{
    border: 2px solid var(--main);
    color: var(--main);
}
.one:hover{
    border:2px solid var(--white);
    background: transparent;
}
.two:hover{
    background: var(--main);
    color: var(--white);
}
.testimonials,
.services,
.contact,
.about{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.about{
    padding: 50px 0;
    background: linear-gradient(to right, rgba(5, 0, 48, .9), rgba(5, 0, 48, .9)),  url(../images/graphic1.png);
    background-position: center;
    background-repeat: repeat;
    background-size: contain;
    color: var(--white);
}
/* .services{ 
    /* padding: 50px 0; */
    /* background: var(--offwhite); */
/* }  */
.sub-title{
    font-weight: lighter;
}
.title{
    font-size: 1.5rem;
    font-weight: bold;
}
.about-wrapper{
    text-align: center;
    width: 90%;
    max-width: 800px;
    margin-bottom: 50px;
}
.go-wrapper{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    padding: 5px 20px;
    background: var(--main);
    color: var(--white);
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    border:none;
}
.go-wrapper:hover{
    box-shadow: 3px 3px 10px #ccc;
}
.go{
    font-size: 2rem;
}
.services .go-wrapper{
    margin-top: 50px;
}

.service-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}
.service{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:10px;
    padding: 0 20px 40px 20px;
    box-shadow: 3px 3px 10px #ccc;
    border-radius: 5px;
    text-align: center;
    border-color: var(--black);
    color: var(--black);
    background-color: var(--white);
}
.about-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0 20px 40px 20px;
    box-shadow: 3px 3px 10px #ccc;
    border-radius: 5px;
    text-align: center;
    border-color: var(--white);
    color: var(--white);

}
.service-header{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background: var(--main);
    margin-bottom: 50px;
}
.service-icon{
    color: var(--black);
    font-size: 3rem;
}
.service-main-title{
    font-weight: bold;
    color:var(--white);
    font-size: 1.2rem;
}
.service-title {
    font-weight: bold;
    color: var(--main);
    font-size: 1.2rem;
}
.service-description{
    font-weight: 300;
}

.contact-form{
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
    gap: 20px;
}
.testimonial{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.testimonial-info{
    color: var(--white);
    font-weight: lighter;
    width: 80%;
    max-width: 500px;
    font-size: 1rem;
}
.testimonials-wrapper{
    display: flex;
    overflow-x: scroll;
    gap: 20px;
    width: 95%;
    max-width: 1400px;
    margin: 0 auto;
}
.testimonial-details{
    padding: 20px 20px 40px 20px;
    background: linear-gradient(to right, rgba(5,10,48, .95), rgba(5,10,48, .95)),  url(../images/graphic.png);
    background-position: center;
    background-repeat: repeat;
    background-size: contain;
    width: 300px;
    border-radius: 50px;
    color: var(--white);
    font-weight: lighter;
    font-size: .9rem;
    position: relative;
}
.testimonial-details::after{
    content: "";
    height: 0px;
    width: 0px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-right: solid 25px transparent;
    border-left: solid 25px transparent;
    border-top: solid 25px var(--black);
}
.testimonial-img{
    margin-top: 50px;
    width: 80px;
}
.contact{
    background:var(--white) ;
    color: var(--black);
    padding: 50px;
}
.contact-sub-title{color: var(--black);
}
.location,
.address,
.mail,
.call{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
}
.contact-icon{
    font-size: 2rem;
    color: var(--white);
}
.contact-info {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 600px;
    max-height: 100%;
}

.contact-info img {
    width: 100%;
    height: 50%;
    object-fit: cover;
    aspect-ratio: 16/9;
}
.contact-form{
    background: var(--black);
    color: var(--white);
    padding: 50px 0;
    width: 100vw;
    align-items: center;
}
.names{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
label{
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-weight: bold;
}
label input{
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid var(--main);
    width: 90vw;
    padding: 10px;
}
.submit{
    width: 90vw;
    padding: 10px;
    background: var(--main);
    border: none;
    color: var(--white);
    font-weight: bold;
    cursor: pointer;
}
.submit:hover{
    box-shadow: 3px 3px 10px #ccc;
}
.bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 5px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
}
@media (max-width: 768px) {
    .bar {
        display: none;
    }
}
.bar-wrapper{
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    gap: 10px;
    margin-left: auto;
    color: var(--black);
}
.bar-wrapper a {
    color: var(--black);
}
.bar-icon{
    font-size: 1rem;
    color: var(--black);
}
@media only screen and (min-width: 600px) {
    .contact-wrapper,
    .names{
        display: flex;
    }
    .button{
        flex-direction: row;
        gap: 50px;
    }
    .names{
        width: 100%;
    }
    .contact-wrapper{
        gap: 50px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }
    .contact-form{
        width: 50%;
        padding: 50px 20px;
    }
    label input{
        width: 100%;
    }
    .message{
        width: 100%;
    }
    .submit{
        width: 70%;
    }
    .service-wrapper{
        display: grid;
        grid-template-columns: repeat(3 ,1fr);
    }
    .testimonial-details{
        padding: 50px;
        width: 500px;
    }
    .service, .about-card{
        width: 450px;
    }
    .bar-wrapper {
        flex-direction: row;
        align-self: flex-end;
    }
}